import React from 'react'
import styles from "./FoodCard.module.scss"
import { Button } from '../Button/Button'

export const FoodCard = ({ food, change }) => {

    const addToBasket = (food) => {
        let localBasket = JSON.parse(localStorage.getItem("basket")) || []
        let address = Number(JSON.parse(localStorage.getItem("address"))) || null

        const existingItem = localBasket ? localBasket.find((item) => item._id === food._id) : false;
        if (existingItem) {
            localBasket = localBasket.map((item) =>
                item._id === food._id ? { ...item, count: item.count + 1 } : item
            );
        } else {

            localBasket = [...localBasket, { ...food, count: 1 }];
        }
        localStorage.setItem("basket", JSON.stringify(localBasket))
        change()
    }
    return (
        <div className={styles.card}>
            <img src={`/images/menu/${food.image}`} alt="" />
            <h5>{food.name}</h5>
            <span>{food.description}</span>
            <h6>{food.price}֏</h6>
            <Button onClick={() => addToBasket(food)} size='small'>Ավելացնել զամբյուղ</Button>
        </div>
    )
}
