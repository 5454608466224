import React from 'react'
import styles from "./Payment.module.scss"
import { Footer } from '../../components/Footer/Footer'
import { useSearchParams } from 'react-router-dom'
import { failOrder } from '../../services/order'

export const Payment = ({ type = "placeholder" }) => {
    const [searchParams] = useSearchParams();
    const edpBillNo = searchParams.get("EDP_BILL_NO");

    if (type === "fail") {
        failOrder(edpBillNo);

    }
    return (
        type === "success" ? <div className={styles.payment}>
            <img src="/images/card-payment.png" alt="" />
            <h4>Վճարումը հաջողությամբ կատարվել է։ ✅</h4>
            <p>Ձեր պատվերը կմատուցվի հնարավորինս շուտ։</p>
            <p>Եթե հարցեր ունեք, զանգահարեք մեզ։ </p>
            <a style={{ color: "blue" }} href="tel:+37455109050">+(374)55-109-050</a>
            <p style={{ marginBottom: "50px" }}>Շնորհակալություն ձեր պատվերի համար։ 😊</p>
            <a className={styles.btn} href="/">Վերադառնալ գլխավոր էջ</a>
            <div style={{ visibility: "hidden" }} ><a rel="noreferrer" href="https://iconscout.com/illustrations/card-payment" target="_blank">Card Payment</a> by <a href="https://iconscout.com/contributors/kawalanicon" rel="noreferrer" target="_blank">Kawalan Studio</a>
            </div>
            <Footer />
        </div> : type === "fail" ? <div className={styles.payment}>
            <img src="/images/payment-error.png" alt="" />
            <h4>Վճարումը ձախողվեց։ ❌</h4>
            <p>Խնդրում ենք փորձել կրկին կամ կապ հաստատել մեզ հետ։</p>
            <a style={{ color: "blue" }} href="tel:+37455109050">+(374)55-109-050</a>
            <a className={styles.btn} href="/">Վերադառնալ գլխավոր էջ</a>
            <div style={{ visibility: "hidden" }}><a rel="noreferrer" href="https://iconscout.com/illustrations/payment-error" className="text-underline font-size-sm" target="_blank">Payment Error</a> by <a rel="noreferrer" href="https://iconscout.com/contributors/kawalanicon" className="text-underline font-size-sm">Kawalan Studio</a> on <a href="https://iconscout.com" className="text-underline font-size-sm">IconScout</a>
            </div>
            <Footer />
        </div> : <div className={styles.payment}>
            <img src="/images/delivery.png" alt="" />
            <h4>Պատվերը հաջողությամբ ընդունվել է: ✅</h4>
            <p>Ձեր պատվերը կմատուցվի հնարավորինս շուտ։</p>
            <p>Եթե հարցեր ունեք, զանգահարեք մեզ։ </p>
            <a style={{ color: "blue" }} href="tel:+37455109050">+(374)55-109-050</a>
            <p style={{ marginBottom: "50px" }}>Շնորհակալություն ձեր պատվերի համար։ 😊</p>
            <a className={styles.btn} href="/">Վերադառնալ գլխավոր էջ</a>
            <Footer />
        </div>

    )
}
