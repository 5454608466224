import axios from "axios";

export const createOrder = async (basket) => {
  return axios
    .post(`${process.env.REACT_APP_URL}/order/create`, basket)
    .then(({ data }) => data)
    .catch((error) => {
      console.error("Error fetching food data", error);
    });
};

export const getOrders = async () => {
  return axios
    .get(`${process.env.REACT_APP_URL}/order/getall`)
    .then(({ data }) => data)
    .catch((error) => {
      console.error("Error fetching food data", error);
    });
};

export const deliveredOrder = async (id) => {
  const token = window.localStorage.getItem("token");
  return axios
    .post(
      `${process.env.REACT_APP_URL}/order/update/${id}`,
      { orderStatus: "Առաքված" },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then(({ data }) => data)
    .catch((error) => {
      console.error("Error fetching food data", error);
    });
};
export const paidOrder = async (id) => {
  const token = window.localStorage.getItem("token");
  return axios
    .post(
      `${process.env.REACT_APP_URL}/order/update/${id}`,
      { paymentStatus: "Վճարված" },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then(({ data }) => data)
    .catch((error) => {
      console.error("Error fetching food data", error);
    });
};

export const failOrder = async (billNo) => {
  const token = window.localStorage.getItem("token");
  return axios
    .get(`${process.env.REACT_APP_URL}/order/${billNo}`, {
      headers: {
        Authorization: token,
      },
    })
    .then(({ data }) => {
      axios
        .post(
          `${process.env.REACT_APP_URL}/order/update/${data._id}`,
          { paymentStatus: "Անհաջող" },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then(({ data }) => data)
        .catch((error) => {
          console.error("Error fetching food data", error);
        });
    })
    .catch((error) => {
      console.error("Error fetching food data", error);
    });
};

export const IdramPayment = async (amount, billNo) => {
  return axios
    .post(`${process.env.REACT_APP_URL}/pay`, {
      amount,
      billNo: billNo,
    })
    .then(({ data }) => {
      if (data.paymentUrl) {
        window.location.href = data.paymentUrl;
        return true;
      } else {
        alert("Payment initiation failed");
        return false;
      }
    })
    .catch((error) => {
      console.error("Error fetching food data", error);
    });
};
