import React from "react";
import styles from "./Button.module.scss";

export const Button = ({
    size = "medium",
    variant = "primary",
    disabled = false,
    onClick,
    children,
    icon,
    type = "button"
}) => {
    const buttonClassNames = `
    ${styles.btn} 
    ${styles[size]} 
    ${styles[variant]} 
    ${disabled ? styles.disabled : ""}
  `;

    return (
        <button
            className={buttonClassNames}
            onClick={!disabled ? onClick : undefined}
            disabled={disabled}
            type={type}
        >
            {icon === "left" && <div className={styles.icon}>
                <svg viewBox="0 0 24 24" width="34px" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <rect width="24" height="24" fill="white"></rect> <path fillRule="evenodd" clipRule="evenodd" d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM15.5963 10.3318C16.8872 11.0694 16.8872 12.9307 15.5963 13.6683L11.154 16.2068C9.9715 16.8825 8.5002 16.0287 8.5002 14.6667L8.5002 9.33339C8.5002 7.97146 9.9715 7.11762 11.154 7.79333L15.5963 10.3318Z" fill="#ee6e26"></path> </g></svg>
            </div>}
            <span className={styles.text}>{children}</span>
            {icon === "right" && <div className={styles.icon}></div>}
        </button>
    );
};