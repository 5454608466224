import axios from "axios";

export const getFood = async () => {
  return axios
    .get(`${process.env.REACT_APP_URL}/food`)
    .then(({ data }) => data)
    .catch((error) => {
      console.error("Error fetching food data", error);
    });
};
export const getFoodByAddress = async (address) => {
  return axios
    .post(`${process.env.REACT_APP_URL}/food/address`, { address })
    .then(({ data }) => data)
    .catch((error) => {
      console.error("Error fetching food data", error);
    });
};

export const deleteFood = async (id) => {
  const token = window.localStorage.getItem("token");

  return axios
    .post(
      `${process.env.REACT_APP_URL}/food/delete?id=${id}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then(() => {
      console.log("deleted");
    })
    .catch((error) => {
      console.error("Error fetching food data", error);
    });
};

export const CreateFood = async (food) => {
  const token = window.localStorage.getItem("token");

  return axios
    .post(`${process.env.REACT_APP_URL}/food/create`, food, {
      headers: {
        Authorization: token,
      },
    })
    .then(() => {
      console.log("created");
    })
    .catch((error) => {
      console.error("Error fetching food data", error);
    });
};
