import React, { useEffect, useState } from 'react'
import styles from "./Admin.module.scss"
import { checkAuth } from '../../services/user'
import { useNavigate } from 'react-router-dom'
import { getOrders } from '../../services/order'
import { OrderList } from '../../components/OrderList/OrderList'
import { FoodForm } from '../../components/FoodForm/FoodForm'

export const Admin = () => {
    const navigate = useNavigate()
    const [orders, setOrders] = useState([])
    const [foods, setFoods] = useState([])
    const [change, setChange] = useState(0)
    useEffect(() => {
        setFoods([])
        setOrders([])
        if (!checkAuth()) {
            navigate("/login")
        } else {
            getOrders().then((data) => setOrders(data)).catch(err => console.log(err))

        }
        setTimeout(() => {
            window.location.reload()
        }, 300000)
    }, [change, navigate])
    return (
        <div>
            <div className={`${styles.lists}`}>
                <div className={styles.lists_el}>
                    <h2>Կանխիկ</h2>
                    <OrderList list={orders.filter(ord => ord.paymentWay === "Կանխիկ")} change={() => setChange(change + 1)} />
                </div>
                <div className={styles.lists_el}>
                    <h2>Իդրամ</h2>
                    <OrderList list={orders.filter(ord => ord.paymentWay === "Իդրամ")} change={() => setChange(change + 1)} />
                </div>
            </div>
            {/* <FoodForm /> */}
        </div>
    )
}
