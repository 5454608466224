import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import styles from "./CheckoutForm.module.scss"
import { Button } from '../../ui/Button/Button';
import { createOrder, IdramPayment } from '../../services/order';
import { Link, useNavigate } from 'react-router-dom';

export const CheckoutForm = () => {
    const [basket, setBasket] = useState([]);

    useEffect(() => {
        const localBasket = JSON.parse(localStorage.getItem("basket"))

        if (localBasket) {
            setBasket(localBasket)
            if (localBasket.length === 0) {
                navigate("/")
            }
        }
    }, [])
    const { register, handleSubmit, formState: { errors }, watch } = useForm();
    const acceptTerms = watch("acceptTerms", false);
    const policy = watch("policy", false);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate()
    const [paymentWay, setPaymentWay] = useState("Կանխիկ")

    const onSubmit = async (data) => {
        const localBasket = JSON.parse(localStorage.getItem("basket"))
        setIsSubmitting(true);
        const total = localBasket.reduce((ttl, { price, count }) => ttl + price * count, 0)
        const orderData = {
            name: data.name,
            phone: data.phone,
            address: data.address,
            basket: localBasket.map(food => ({ foodId: food._id, count: food.count, price: food.price, name: food.name })),
            details: data.orderDetails,
            totalAmount: total + (total < 10000 ? 1000 : 0),
            billNo: `Bill_NO-${Date.now()}`,
            paymentWay,
        }
        await createOrder(orderData).then(data => {
            window.localStorage.removeItem("basket")
            if (paymentWay === "Կանխիկ") {
                navigate("/order")
            } else {
                IdramPayment(orderData.totalAmount, orderData.billNo).then((paymentSuccess) => {
                    if (!paymentSuccess) {
                        setIsSubmitting(false);
                        return;
                    }

                })
            }
        }).catch(err => console.log(err))


    };

    return (
        <div className={`${styles.checkout}`}>

            <form className={styles.checkout_form} onSubmit={handleSubmit(onSubmit)}>

                <div className={styles.checkout_form_group}>
                    <label htmlFor="name">Անուն Ազգանուն</label>
                    <input
                        type="text"
                        id="name"
                        {...register('name', { required: 'Անուն Ազգանուն պարտադիր է լրացնել' })}
                    />
                    {errors.name && <p className={styles.error}>{errors.name.message}</p>}
                </div>

                <div className={styles.checkout_form_group}>

                    <label htmlFor="phone">Հեռախոսահամար</label>
                    <input
                        type="text"
                        id="phone"
                        {...register('phone', {
                            required: 'Հեռախոսահամարը պարտադիր է լրացնել',
                            pattern: {
                                value: /^(0\d{8}|\+374\d{8})$/,
                                message: 'Հեռախոսահամարը պետք է լինի 0XXXXXXXX կամ +374XXXXXXXX ձևաչափով',
                            },
                        })}
                    />

                    {errors.phone && <p className={styles.error}>{errors.phone.message}</p>}
                </div>

                <div className={styles.checkout_form_group}>

                    <label htmlFor="address">Հասցե</label>
                    <input
                        type="text"
                        id="address"
                        {...register('address', {
                            required: 'Հասցեն պարտադիր է լրացնել',
                        })}
                    />
                    {errors.address && <p className={styles.error}>{errors.address.message}</p>}
                </div>

                <div className={styles.checkout_form_group}>

                    <label htmlFor="orderDetails">Պատվերի մանրամասներ</label>
                    <textarea
                        id="orderDetails"
                        {...register('orderDetails')}
                    ></textarea>
                    {errors.orderDetails && <p className={styles.error}>{errors.orderDetails.message}</p>}
                </div>
                <div className={styles.checkbox}>
                    <label htmlFor="accept" >
                        <input id='accept' type="checkbox" {...register("acceptTerms", { required: true })} />
                        <svg viewBox="0 0 24 24" width="40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path
                                    d="M18 20.75H6C5.27065 20.75 4.57118 20.4603 4.05546 19.9445C3.53973 19.4288 3.25 18.7293 3.25 18V6C3.25 5.27065 3.53973 4.57118 4.05546 4.05546C4.57118 3.53973 5.27065 3.25 6 3.25H14.86C15.0589 3.25 15.2497 3.32902 15.3903 3.46967C15.531 3.61032 15.61 3.80109 15.61 4C15.61 4.19891 15.531 4.38968 15.3903 4.53033C15.2497 4.67098 15.0589 4.75 14.86 4.75H6C5.66848 4.75 5.35054 4.8817 5.11612 5.11612C4.8817 5.35054 4.75 5.66848 4.75 6V18C4.75 18.3315 4.8817 18.6495 5.11612 18.8839C5.35054 19.1183 5.66848 19.25 6 19.25H18C18.3315 19.25 18.6495 19.1183 18.8839 18.8839C19.1183 18.6495 19.25 18.3315 19.25 18V10.29C19.25 10.0911 19.329 9.90032 19.4697 9.75967C19.6103 9.61902 19.8011 9.54 20 9.54C20.1989 9.54 20.3897 9.61902 20.5303 9.75967C20.671 9.90032 20.75 10.0911 20.75 10.29V18C20.75 18.7293 20.4603 19.4288 19.9445 19.9445C19.4288 20.4603 18.7293 20.75 18 20.75Z"
                                    fill={acceptTerms ? "#f0a347" : errors.acceptTerms ? "red" : "#000"}
                                ></path>
                                {acceptTerms && (
                                    <path
                                        d="M10.5 15.25C10.3071 15.2352 10.1276 15.1455 10 15L7.00001 12C6.93317 11.86 6.91136 11.7028 6.93759 11.5499C6.96382 11.3971 7.03679 11.2561 7.14646 11.1464C7.25613 11.0368 7.3971 10.9638 7.54996 10.9376C7.70282 10.9113 7.86006 10.9331 8.00001 11L10.47 13.47L19 4.99998C19.14 4.93314 19.2972 4.91133 19.4501 4.93756C19.6029 4.96379 19.7439 5.03676 19.8536 5.14643C19.9632 5.2561 20.0362 5.39707 20.0624 5.54993C20.0887 5.70279 20.0669 5.86003 20 5.99998L11 15C10.8724 15.1455 10.693 15.2352 10.5 15.25Z"
                                        fill={acceptTerms ? "#f0a347" : "#000"}
                                    ></path>
                                )}
                            </g>
                        </svg>
                        <p style={{ color: errors.acceptTerms ? "red" : "#000" }}>Ես համաձայն եմ <Link to="/terms">պայմանների</Link> հետ</p>
                    </label>
                </div>
                <div className={styles.checkbox}>
                    <label htmlFor="policy" >
                        <input id='policy' type="checkbox" {...register("policy", { required: true })} />
                        <svg viewBox="0 0 24 24" width="40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path
                                    d="M18 20.75H6C5.27065 20.75 4.57118 20.4603 4.05546 19.9445C3.53973 19.4288 3.25 18.7293 3.25 18V6C3.25 5.27065 3.53973 4.57118 4.05546 4.05546C4.57118 3.53973 5.27065 3.25 6 3.25H14.86C15.0589 3.25 15.2497 3.32902 15.3903 3.46967C15.531 3.61032 15.61 3.80109 15.61 4C15.61 4.19891 15.531 4.38968 15.3903 4.53033C15.2497 4.67098 15.0589 4.75 14.86 4.75H6C5.66848 4.75 5.35054 4.8817 5.11612 5.11612C4.8817 5.35054 4.75 5.66848 4.75 6V18C4.75 18.3315 4.8817 18.6495 5.11612 18.8839C5.35054 19.1183 5.66848 19.25 6 19.25H18C18.3315 19.25 18.6495 19.1183 18.8839 18.8839C19.1183 18.6495 19.25 18.3315 19.25 18V10.29C19.25 10.0911 19.329 9.90032 19.4697 9.75967C19.6103 9.61902 19.8011 9.54 20 9.54C20.1989 9.54 20.3897 9.61902 20.5303 9.75967C20.671 9.90032 20.75 10.0911 20.75 10.29V18C20.75 18.7293 20.4603 19.4288 19.9445 19.9445C19.4288 20.4603 18.7293 20.75 18 20.75Z"
                                    fill={policy ? "#f0a347" : errors.policy ? "red" : "#000"}
                                ></path>
                                {policy && (
                                    <path
                                        d="M10.5 15.25C10.3071 15.2352 10.1276 15.1455 10 15L7.00001 12C6.93317 11.86 6.91136 11.7028 6.93759 11.5499C6.96382 11.3971 7.03679 11.2561 7.14646 11.1464C7.25613 11.0368 7.3971 10.9638 7.54996 10.9376C7.70282 10.9113 7.86006 10.9331 8.00001 11L10.47 13.47L19 4.99998C19.14 4.93314 19.2972 4.91133 19.4501 4.93756C19.6029 4.96379 19.7439 5.03676 19.8536 5.14643C19.9632 5.2561 20.0362 5.39707 20.0624 5.54993C20.0887 5.70279 20.0669 5.86003 20 5.99998L11 15C10.8724 15.1455 10.693 15.2352 10.5 15.25Z"
                                        fill={policy ? "#f0a347" : "#000"}
                                    ></path>
                                )}
                            </g>
                        </svg>
                        <p style={{ color: errors.policy ? "red" : "#000" }}>Ես ծանոթ եմ <Link to="/policy">Գաղտնիության քաղաքականության</Link> հետ</p>
                    </label>
                </div>
                <div className={styles.paymentWay}>
                    <button type='button' className={paymentWay === "Կանխիկ" ? styles.active : ""} onClick={() => setPaymentWay("Կանխիկ")}>Կանխիկ</button>
                    <button type='button' className={paymentWay === "Իդրամ" ? styles.active : ""} onClick={() => setPaymentWay("Իդրամ")}>Իդրամ</button>
                </div>
                <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? 'Հաստատում...' : 'Հաստատել պատվերը'}
                </Button>
            </form>
        </div>
    );
};
