import React, { useEffect, useState } from 'react'
import styles from "./Terms.module.scss"
import { Header } from '../../components/Header/Header'
import { Footer } from '../../components/Footer/Footer'

export const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Header />
            <div className={styles.terms}>
                <div className='container'>
                    <h2>Պայմաններ</h2>
                    <h3>Վերադարձի և չեղարկման քաղաքականություն</h3>
                    <p>Մենք մշտապես ձգտում ենք ապահովել մեր հաճախորդների գոհունակությունը: Այդ իսկ պատճառով սահմանել ենք հստակ քաղաքականություն՝ կապված վերադարձի և չեղարկման հետ:</p>

                    <h4>Վերադարձի պայմաններ</h4>
                    <p>Պատրաստված սննդի վերադարձ չի իրականացվում՝ սննդի բնույթից ելնելով:
                        Վերադարձը հնարավոր է միայն այն դեպքերում, երբ պատվերի մեջ կա սխալ (օրինակ՝ սխալ ապրանք) կամ սնունդը չի համապատասխանում որակի պահանջներին:
                        Նման դեպքերում խնդրում ենք կապ հաստատել մեզ հետ պատվերը ստանալուց հետո 2 ժամվա ընթացքում:</p>

                    <h5>Պատվերի չեղարկում</h5>
                    <p>Պատվերի չեղարկումը հնարավոր է միայն մինչև պատրաստման գործընթացի սկիզբը: Եթե ցանկանում եք չեղարկել պատվերը, խնդրում ենք անմիջապես կապ հաստատել մեր աջակցման թիմի հետ:</p>

                    <h5>Օնլայն վճարումների վերադարձ</h5>
                    <p>Եթե վճարումը կատարվել է օնլայն և պատվերը չեղարկվել է ժամանակին, ապա գումարը կվերադարձվի նույն վճարային միջոցին՝ 3-5 աշխատանքային օրվա ընթացքում:</p>

                    <p>Վերադարձի հարցերով խնդրում ենք կապ հաստատել մեր աջակցման թիմի հետ</p>

                    <h4>Առաքման պայմաններ</h4>
                    <p>Մենք ձգտում ենք ապահովել արագ և որակյալ առաքում՝ մեր հաճախորդների գոհունակությունը ապահովելու համար</p>

                    <h5>Առաքման գոտի</h5>
                    <p>Առաքումները կատարվում են միայն Երևան քաղաքում: <br />
                        Մեր առաքման ծառայությունը չի տարածվում տվյալ գոտուց դուրս: </p>
                    <h5>Առաքման ժամեր</h5>
                    <p>
                        Պատվերները ընդունվում են ամեն օր՝ 10:00-ից 22:00: <br />
                        Առաքման միջին ժամանակը կազմում է 10-60 րոպե՝ կախված գտնվելու վայրից:</p>

                    <h5>Առաքման վճար</h5>
                    <p>10000 դրամից բարձր պատվերների համար առաքումը անվճար է: <br />
                        10000 դրամից ցածր պատվերների դեպքում առաքման վճարը կազմում է 1000 դրամ Երևանում:</p>

                    <h5>Պատվերի հաստատում</h5>
                    <p>Պատվերի կատարումից հետո մեր թիմը կզանգահարի Ձեզ՝ պատվերը հաստատելու համար:
                        Եթե պատվերը օնլայն է վճարվել, այն անմիջապես կփոխանցվի պատրաստման գործընթացին:</p>
                    <h5>Պատվերի ստացում</h5>
                    <p>Խնդրում ենք ստուգել Ձեր պատվերը ստանալիս: Եթե առկա են անհամապատասխանություններ, խնդրում ենք անմիջապես տեղեկացնել մեր թիմին:
                        Այս պայմանները մշակված են՝ ապահովելու հարմարավետ և հեշտ պատվերների գործընթաց՝ մեր հաճախորդների համար:</p>
                </div>
            </div >
            <Footer />
        </>
    )
}
