import React from 'react'
import styles from "./OrderList.module.scss"
import { Button } from '../../ui/Button/Button'
import { deliveredOrder, paidOrder } from '../../services/order'
import { Loading } from '../Loading/Loading'

export const OrderList = ({ list, change }) => {

    return (
        <div className={styles.order_ul}>{list.length ? list.map((order, i) => {
            return (<div className={styles.order_li} key={i}>
                <div className={styles.order_li_flex}>
                    <p>{order.name} {order.phone}</p>
                    <span>{order.address}</span>
                    <span>{order.details}</span>
                    <div className={styles.order_basket}>
                        {order.basket.map((food, i) => {
                            return <span key={i}>{food.name} ({food.count} հատ) {food.count * food.price}֏</span>
                        })}
                    </div>
                    <span style={{ textAlign: "right", borderBottom: "1px solid", paddingBottom: "5px" }}>{order.totalAmount}֏</span>
                    <span>վճարում - <span style={{ color: order.paymentStatus === "Ընթացքում" ? "#bdbd00" : order.paymentStatus === "Անհաջող" ? "red" : "#00c800" }}>{order.paymentStatus}</span></span>
                    <span>{new Date(order.createdAt).toLocaleString()}</span>
                </div>
                <div className={styles.order_actions}>
                    {order.paymentWay === "Կանխիկ" ? <Button onClick={async () => {
                        await paidOrder(order._id)
                        window.location.reload()
                    }} size='small'>Վճարված</Button> : <></>}

                    <Button onClick={async () => {
                        await deliveredOrder(order._id)
                        window.location.reload()
                    }} size='small'>Առաքված</Button>
                </div>
            </div>)
        }) : <Loading />}</div>
    )
}
