import React, { useEffect, useState } from 'react';
import { FoodCard } from '../../ui/FoodCard/FoodCard';
import styles from "./Menu.module.scss";
import { getFoodByAddress } from '../../services/food';

const addresses = [
  { id: 1, location: "Ահարոնյան 12/5", image: "/address/aharonyan.jpg" },
  { id: 2, location: "Արցախի 1/3", image: "/address/arcax.jpg" },
  { id: 3, location: "Բաղրամյան 25/3", image: "/address/bagramyan.jpg" },
  { id: 4, location: "Բագրատունյաց 20", image: "/address/bagratunyac.jpg" },
  { id: 5, location: "Քրիստափորի 14/7", image: "/address/qrst.jpeg" },
  { id: 6, location: "Սարյան 23", image: "/address/saryan.jpg" }
];

export const Menu = ({ change }) => {
  const [menu, setMenu] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);

  useEffect(() => {
    localStorage.setItem("address", selectedAddress);
    if (selectedAddress != null) {
      let basket = JSON.parse(localStorage.getItem("basket")) || [];
      const isValidBasket = basket.every(item => item.address.includes(selectedAddress));
      if (!isValidBasket) {
        localStorage.removeItem("basket");
      }
    }
  }, [selectedAddress]);

  const fetchMenuByAddress = async (id) => {
    setLoading(true);
    setError(null);
    try {
      const data = await getFoodByAddress(id);
      setMenu(
        data.sort((a, b) => (b.food === a.food ? b.price - a.price : b.food - a.food))
      );
    } catch (err) {
      setError("Failed to fetch food data");
    }
    setLoading(false);
  };





  return (
    <div className={`${styles.menu} container`} id='menu'>
      <h3>{selectedAddress == null ? "Ընտրել հասցե" : "Մենյու"}</h3>
      <div className={styles.address_buttons}>
        {addresses.map(({ id, location, image }) => (
          <button
            key={id}
            className={selectedAddress == id ? styles.active : ""}
            onClick={() => {
              setSelectedAddress(id);
              fetchMenuByAddress(id);
            }}
          >
            <img src={image} alt={location} />
            {location}
          </button>
        ))}
      </div>
      {loading && <div className={`${styles.loading} container`}><span></span></div>}
      {error && <div>{error}</div>}
      {!loading && !error && selectedAddress && (
        <div className={styles.menu_list}>
          {menu.map((food, i) => (
            <FoodCard food={food} key={i} change={change} />
          ))}
        </div>
      )}
    </div>
  );
};
